*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: linear-gradient(to right, #00aaff, #00ff6c);
}

.calculator-grid {
  display: grid;
  margin-top: 2rem;
  justify-content: center;
  grid-template-columns: repeat(4, 6rem);
  grid-template-rows: minmax(7rem, auto) repeat(5, 6rem);
}

.calculator-grid > button {
  cursor: pointer;
  font-size: 2rem;
  border: 1px solid white;
  outline: none;
  background-color: rgba(255, 255, 255, 0.75);
}

.calculator-grid > button:hover,
.calculator-grid > button:focus {
  background-color: rgba(255, 255, 255, 0.9);
}

.span-two {
  grid-column: span 2;
}

.output {
  grid-column: 1/ -1;
  /* တစ်ကြောင်းအပြည့်ယူချင်တာမို့ */
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0.75rem;
  word-wrap: break-word;
  word-break: break-all;
}

.output .previous-operand {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.5rem;
}

.output .current-operand {
  color: white;
  font-size: 2.5rem;
}
